<template>
  <div @click="checkParentModal">
    <main class="container mx-auto mt-10 mb-10">
      <div class="flex flex-col space-x-2 md:flex-row">
        <!-- filtre -->
        <div class="relative flex justify-start min-w-max">
          <div class="w-full mb-8 bg-gray-100 md:mb-0 md:w-64">
            <div class="flex flex-wrap items-center justify-center p-4 mx-auto">
              <h4 class="p-2 overflow-hidden text-center text-white bg-black rounded shadow-md text-bold">
                {{ $t('FILTERS') }}
              </h4>
            </div>
            <div class="py-2 bg-gray-100">
              <!-- START SEARCH FILTER -->
              <div class="px-1">
                <div class="">
                  <div class="flex p-1 my-2 bg-white border border-gray-200 rounded">
                    <input :placeholder="$t('Keyword')" v-model="inputSearch" @input="
                      pagination.page = 0;
                    getMachines();
                    " class="w-full p-1 px-2 text-gray-800 outline-none appearance-none" />
                    <div>
                      <button @click="
                        inputSearch = '';
                      pagination.page = 0;
                      getMachines();
                      "
                        class="flex items-center w-6 h-full text-gray-400 outline-none cursor-pointer focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="w-4 h-4 feather feather-x">
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END SEARCH FILTER -->
              <!-- START activity FILTER -->
              <div class="px-1" id="modal">
                <div class="">
                  <div class="flex p-1 my-2 bg-white border border-gray-200 rounded">
                    <input :placeholder="$t('Activity')" v-model="inputActivity" @focus="getActivity"
                      @input="getActivity" class="w-full p-1 px-2 text-gray-800 outline-none appearance-none" />
                    <div>
                      <button @click="
                        inputActivity = '';
                      inputCode = '';
                      inputSubCategory = '';
                      inputSubCode = '';
                      pagination.page = 0;
                      getMachines();
                      "
                        class="flex items-center w-6 h-full text-gray-400 outline-none cursor-pointer focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="w-4 h-4 feather feather-x">
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="isActivityOpen"
                  class="absolute z-40 w-full overflow-y-auto rounded shadow top-100 lef-0 max-h-select max-h-96">
                  <div class="flex flex-col w-full">
                    <div class="w-full border-b border-gray-100 rounded-t cursor-pointer hover:bg-teal-100" style=""
                      v-for="(activity, index) in activitys" :key="'activity' + index" @click="
                        setInputActivity(activity.attributes);
                      inputCode = deleteNumber(
                        activity.attributes.code.trim(),
                      );
                      pagination.page = 0;
                      getMachines();
                      ">
                      <div
                        class="relative flex items-center w-full p-2 pl-2 bg-white border-l-2 border-transparent hover:bg-gray-100 hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                        <div class="flex items-center w-full">
                          <div class="mx-2 leading-6">
                            {{
                              $i18n.locale == 'fr'
                                ? activity.attributes.descFR
                                : activity.attributes.descEN.trim()
                                  ? activity.attributes.descEN
                                  : activity.attributes.descFR
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END activity FILTER -->

              <div class="px-1" id="modal">
                <div class="">
                  <div class="flex p-1 my-2 bg-white border border-gray-200 rounded" :style="inputActivity && inputCode ? 'opacity: 1' : 'opacity: 0.4'
                    ">
                    <input :disabled="inputActivity && inputCode ? false : true" :placeholder="$t('subActivity')"
                      v-model="inputSubCategory" @focus="getSubCategory" @input="getSubCategory"
                      class="w-full p-1 px-2 text-gray-800 outline-none appearance-none" />
                    <div>
                      <button @click="
                        inputSubCategory = '';
                      inputSubCode = '';
                      pagination.page = 0;
                      getMachines();
                      "
                        class="flex items-center w-6 h-full text-gray-400 outline-none cursor-pointer focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="w-4 h-4 feather feather-x">
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="isSubCategoryOpen"
                  class="absolute z-40 w-full overflow-y-auto rounded shadow top-100 lef-0 max-h-select max-h-96">
                  <div class="flex flex-col w-full">
                    <div class="w-full border-b border-gray-100 rounded-t cursor-pointer hover:bg-teal-100" style=""
                      v-for="(subCategory, index) in subCategories" :key="'subCategory' + index" @click="
                        setInputSubCategory(subCategory.attributes);
                      inputSubCode = subCategory.attributes.code.trim();
                      pagination.page = 0;
                      getMachines();
                      ">
                      <div v-if="
                        subCategory.attributes.code.trim() != inputCode + '00'
                      "
                        class="relative flex items-center w-full p-2 pl-2 bg-white border-l-2 border-transparent hover:bg-gray-100 hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                        <div class="flex items-center w-full">
                          <div class="mx-2 leading-6">
                            {{
                              $i18n.locale == 'fr'
                                ? subCategory.attributes.descFR
                                : subCategory.attributes.descEN.trim()
                                  ? subCategory.attributes.descEN
                                  : subCategory.attributes.descFR
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END SUB CATEGORY FILTER -->
              <div class="grid px-1 mb-6 justify-items-center">
                <label for="">{{ $t('After (Year)') }}</label>
                <input v-model="inputyear" @input="
                  pagination.page = 0;
                getMachines();
                " name="constructionOld" type="number" :placeholder="$t('Year of manufacture')"
                  class="form-control block w-full md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none" />
              </div>
            </div>
          </div>
        </div>
        <!-- card machine -->
        <div class="w-full">
          <div class="flex flex-col items-center">
            <!-- card goes here -->
            <div v-if="machines.length > 0" class="flex flex-wrap justify-center w-full -m-4 space-x-0">
              <div class="p-4 xl:w-1/3" v-for="machine in machines" :key="'m' + machine.id">
                <a :href="'machine/' + machine.id">
                  <div class="p-6 bg-gray-100 rounded-lg">
                    <img
                      class="object-cover object-center w-full mb-6 rounded lg:h-60 xl:h-56 md:h-64 sm:h-72 xs:h-72 h-72"
                      :src="machine.attributes.images.data
                        ? machine.attributes.images.data.filter((img) => {
                          return img.attributes.caption === 'true';
                        })[0]?.attributes.url || '/default.jpg'
                        : '/default.jpg'
                        " :alt="'Ref: ' +
                          machine.attributes.bible_material.data.attributes.code.trim() +
                          '-' +
                          machine.attributes.Code
                          " />
                    <h3 class="text-xs font-medium tracking-widest text-indigo-500 title-font">
                      {{
                        machine.attributes.contructionOld > 0
                          ? machine.attributes.contructionOld
                          : ' '
                      }}
                    </h3>
                    <h3 class="mb-4 text-xs font-medium tracking-widest title-font">
                      Ref:
                      {{
                        machine.attributes.bible_material.data.attributes.code.trim() +
                        '-' +
                        machine.attributes.Code
                      }}
                    </h3>
                    <h2 class="text-lg font-medium text-gray-900 title-font">
                      {{ machine.attributes.Brand }}
                    </h2>
                    <p v-if="
                      ($i18n.locale == 'fr' &&
                        machine.attributes.descriptionFR?.trim()) ||
                      ($i18n.locale == 'en' &&
                        machine.attributes.decriptionEN?.trim())
                    " class="h-16 text-base leading-relaxed">
                      {{
                        $i18n.locale == 'fr'
                          ? machine.attributes.descriptionFR?.slice(0, 80)
                          : machine.attributes.decriptionEN?.slice(0, 80)
                      }}
                      {{
                        $i18n.locale == 'fr' &&
                          machine.attributes.descriptionFR?.length > 80
                          ? '...'
                          : $i18n.locale == 'en' &&
                            machine.attributes.decriptionEN?.length > 80
                            ? '...'
                            : ''
                      }}
                    </p>
                    <p v-else class="h-16 text-base leading-relaxed">
                      {{ $t('description_not_available') }}
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div v-else-if="load" class="flex flex-wrap justify-center px-10 py-5 text-center">
              <h1 class="p-2 text-3xl text-white bg-black rounded">
                {{ $t('Loading') }}
              </h1>
            </div>
            <div v-else class="flex flex-wrap justify-center px-10 py-5 text-center">
              <h1 class="p-2 text-3xl text-white bg-black rounded">
                {{ $t('Aucun résultat trouvé') }}
              </h1>
            </div>
            <nav v-if="machines.length > 0" class="flex justify-end mt-4" aria-label="Pagination">
              <ol class="flex">
                <!-- Lien précédent -->
                <li>
                  <a v-if="pagination.page > 1" :href="`${currentLink}&page=${pagination.page - 1}`" rel="prev"
                    class="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg cursor-pointer hover:bg-gray-100 hover:text-gray-700"
                    @click.prevent="pagination.page > 1 ? pagination.page-- && getMachines(true) : null"
                    aria-label="Page précédente">
                    &larr;
                  </a>
                </li>

                <!-- Numéros de page -->
                <li v-for="page in paginationCount" :key="page">
                  <a :href="`${currentLink}&page=${page}`"
                    class="w-full h-full px-3 py-2 leading-tight border border-gray-300 cursor-pointer"
                    :style="pagination.page === page ? 'background:#000;color:#FFF' : 'background:#FFF;color:#000'"
                    :aria-current="pagination.page === page ? 'page' : null">
                    {{ page }}
                  </a>
                </li>

                <!-- Lien suivant -->
                <li>
                  <a v-if="pagination.page < pagination.pageCount" :href="`${currentLink}&page=${pagination.page + 1}`"
                    rel="next"
                    class="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg cursor-pointer hover:bg-gray-100 hover:text-gray-700"
                    @click.prevent="pagination.page < pagination.pageCount ? pagination.page++ && getMachines(true) : null"
                    aria-label="Page suivante">
                    &rarr;
                  </a>
                </li>
              </ol>
            </nav>

          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Card from "@/components/Card";
import axios from 'axios';
import qs from 'qs';
import useSeo from '../composables/useSeo';

export default {
  // name: "All-machine",

  components: {
    // Card,
  },
  computed: {
    currentLink() {
      const currentcode = this.$route.query.code || '';
      const currentsubCode = this.$route.query.subCode || '';
      const currentactivity = this.$route.query.activity || '';
      const currentsubCategory = this.$route.query.subCategory || '';
      const currentsearch = this.$route.query.search || '';
      const currentbrand = this.$route.query.brand || '';
      const currentyear = this.$route.query.year || '';

      const link = `?code=${currentcode}&subCode=${currentsubCode}&activity=${currentactivity}&subCategory=${currentsubCategory}&search=${currentsearch}&brand=${currentbrand}&year=${currentyear}`;
      return link;
    },
  },
  setup() {
    useSeo({
      title: "Achat de Machines Textiles d'Occasion | Jacquart & Fils",
      meta: {
        description:
          'Découvrez notre sélection de machines textiles d’occasion rigoureusement inspectées et prêtes à être intégrées à votre chaîne de production. Profitez de solutions fiables et économiques pour vos besoins industriels.',
        'og:type': 'website',
      },
      canonical: "https://jacquart.com/buy",
    });
  },
  data() {
    return {
      inputSearch: '',
      inputActivity: '',
      inputSubCategory: '',
      inputCode: '',
      inputSubCode: '',
      inputBrand: '',
      inputyear: '',
      isActivityOpen: false,
      isSubCategoryOpen: false,
      isBrandOpen: false,
      machines: [],
      activitys: [],
      subCategories: [],
      brands: [],
      pagination: {
        page: 0,
        pageCount: 0,
        pageSize: 0,
      },
      paginationCount: 5,
      // query: null,
      search: '',
      constructionOld: '',
      selectedBrand: '',
      // activitys: [],
      // categories: [],
      selectedActivity: '',
      selectedCategorie: '',
      load: true,
      BaseUrl: process.env.VUE_APP_STRAPI_API_URL,
      navig: '',
      getQuery: this.$route.query,
      brand: '',
      categories: '',
      activity: '',
      // query: "",
    };
  },
  mounted() {
    if (this.$route.query.search) this.inputSearch = this.$route.query.search;
    if (this.$route.query.activity)
      this.inputActivity = this.$route.query.activity;
    if (this.$route.query.subCategory)
      this.inputSubCategory = this.$route.query.subCategory;
    if (this.$route.query.code) this.inputCode = this.$route.query.code;
    if (this.$route.query.subCode)
      this.inputSubCode = this.$route.query.subCode;
    if (this.$route.query.brand) this.inputBrand = this.$route.query.brand;
    if (this.$route.query.year) this.inputyear = this.$route.query.year;
    if (this.$route.query.page) this.pagination.page = this.$route.query.page;

    this.getMachines();
  },
  watch: {},
  methods: {
    deleteNumber(string) {
      return string.replace(/[0-9]/g, '');
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    async getMachines(goTop) {
      this.load = true;
      this.machines = [];
      this.isBrandOpen = false;
      this.isActivityOpen = false;
      this.isSubCategoryOpen = false;

      // SET PARAMS
      this.$router.push({
        query: {
          code: this.inputCode,
          subCode: this.inputSubCode,
          activity: this.inputActivity,
          subCategory: this.inputSubCategory,
          search: this.inputSearch,
          brand: this.inputBrand,
          year: this.inputyear,
          page: this.pagination.page,
        },
      });

      let query = qs.stringify({
        sort: 'validDate:desc',
        populate: ['images', 'bible_material'],
        pagination: { page: this.pagination.page, pageSize: 30 },
        filters: {
          $and: [
            { Status: { $eq: 'SELLING' } },
            { website: { $eq: 1 } },
            this.$i18n.locale == 'fr'
              ? { descriptionFR: { $containsi: this.inputSearch.trim() } }
              : { decriptionEN: { $containsi: this.inputSearch.trim() } },
            {
              bible_material: {
                code: {
                  $startsWith: this.inputSubCode
                    ? this.inputSubCode
                    : this.inputCode,
                },
              },
            },
            { Brand: { $containsi: this.inputBrand } },
            { contructionOld: { $gte: this.inputyear } },
          ],
        },
      });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/api/machine-fiches?${query}`,
        );
        this.load = false;
        // const uniqueActivity = response.data.data.filter((machine) => machine.attributes.images.data != null);
        this.machines = response.data.data;
        this.pagination = response.data.meta.pagination;
        goTop ? this.scrollToTop() : null;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },

    async getActivity() {
      this.isBrandOpen = false;
      this.isSubCategoryOpen = false;
      let query = qs.stringify({
        sort: this.$i18n.locale == 'fr' ? 'descFR:asc' : 'descEN:asc',
        fields: ['descFR', 'descEN', 'code'],
        filters:
          this.$i18n.locale == 'fr'
            ? {
              descFR: { $contains: this.inputActivity.trim() },
              code: { $contains: '00' },
            }
            : {
              descEN: { $contains: this.inputActivity.trim() },
              code: { $contains: '00' },
            },
      });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/api/bible-materials?${query}`,
        );
        const uniqueActivity = response.data.data.filter(
          (value, index, self) => {
            return (
              self.findIndex(
                (obj) => obj.attributes.descFR === value.attributes.descFR,
              ) === index
            );
          },
        );
        this.activitys = uniqueActivity;
        this.isActivityOpen = true;
      } catch (error) {
        this.error = error;
      }
    },

    async getSubCategory() {
      this.isBrandOpen = false;
      this.isActivityOpen = false;
      this.isSubCategoryOpen = true;
      let query = qs.stringify({
        sort: this.$i18n.locale == 'fr' ? 'descFR:asc' : 'descEN:asc',
        fields: ['descFR', 'descEN', 'code'],
        filters: {
          descFR: { $contains: this.inputSubCategory.trim() },
          code: { $startsWith: this.inputCode },
        },
      });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/api/bible-materials?${query}`,
        );
        const uniqueSubCategory = response.data.data.filter(
          (value, index, self) => {
            return (
              self.findIndex(
                (obj) => obj.attributes.descFR === value.attributes.descFR,
              ) === index
            );
          },
        );
        this.subCategories = uniqueSubCategory;
      } catch (error) {
        this.error = error;
      }
    },

    async getBrands() {
      this.isActivityOpen = false;
      this.isSubCategoryOpen = false;
      this.isBrandOpen = true;
      let query = qs.stringify({
        fields: 'Brand',
        sort: 'Brand:asc',
        filters: {
          Brand: { $contains: this.inputBrand.trim() },
          Status: { $eq: 'SELLING' },
        },
      });

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/api/machine-fiches?${query}`,
        );
        const uniqueBrand = response.data.data.filter((value, index, self) => {
          return (
            self.findIndex(
              (obj) => obj.attributes.Brand === value.attributes.Brand,
            ) === index
          );
        });
        this.brands = uniqueBrand;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },

    setInputActivity(value) {
      this.inputActivity =
        this.$i18n.locale == 'fr'
          ? value.descFR.trim()
          : value.descEN.trim()
            ? value.descEN.trim()
            : value.descFR.trim();
    },
    setInputSubCategory(value) {
      this.inputSubCategory =
        this.$i18n.locale == 'fr'
          ? value.descFR.trim()
          : value.descEN.trim()
            ? value.descEN.trim()
            : value.descFR.trim();
    },
    checkParentModal(event) {
      let parent = event.target.parentElement;
      let found = false;
      while (parent) {
        if (parent.id === 'modal') {
          console.log('ok');
          found = true;
        }
        parent = parent.parentElement;
      }
      if (!found) {
        (this.isActivityOpen = false),
          (this.isBrandOpen = false),
          (this.isSubCategoryOpen = false);
      }
    },
  },
};
</script>
