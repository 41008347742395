<template>
  <div class="w-full h-auto">
    <Carousel snapAlign="center" v-if="imgs && imgs.length > 0">
      <Slide v-for="(image, index) in imgs" :key="index">
        <img
          :src="image.attributes.url"
          :alt="alt"
          style="height: 80vh"
          class="object-contain w-full border-8 border-text-green-800"
        />
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  name: "Slider",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    imgs: null,
    alt: null,
  },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
};
</script>

<style>
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
</style>
