<template>
  <main class="container flex-row mx-auto mt-10 mb-10">
    <div class="w-full">
      <div class="flex flex-col items-center">
        <!-- card goes here -->
        <div
          v-if="opportunities.length > 0"
          class="flex flex-wrap justify-start w-full -m-4 space-x-0"
        >
          <div
            class="p-4 xl:w-1/3"
            v-for="opportunity in opportunities"
            :key="'m' + opportunity.id"
          >
            <a :href="'opportunity/' + opportunity.id">
              <div class="p-6 bg-gray-100 rounded-lg">
                <img
                  class="object-cover object-center w-full mb-6 rounded lg:h-60 xl:h-56 md:h-64 sm:h-72 xs:h-72 h-72"
                  :src="
                    opportunity.attributes.images.data
                      ? opportunity.attributes.images.data[0].attributes.url
                      : '/default.jpg'
                  "
                  alt=""
                />
                <h3 class="font-medium tracking-widest text-center">
                  {{
                    $i18n.locale == "fr"
                      ? opportunity.attributes.title_fr
                      : opportunity.attributes.title_en
                  }}
                </h3>
              </div>
            </a>
          </div>
        </div>
        <div
          v-else-if="load"
          class="flex flex-wrap justify-center px-10 py-5 text-center"
        >
          <h1 class="p-2 text-3xl text-white bg-black rounded">
            {{ $t("Loading") }}
          </h1>
        </div>
        <div
          v-else
          class="flex flex-wrap justify-center px-10 py-5 text-center"
        >
          <h1 class="p-2 text-3xl text-white bg-black rounded">
            {{ $t("Aucun résultat trouvé") }}
          </h1>
        </div>
        <ul v-if="opportunities.length > 0" class="flex justify-center mt-2">
          <li
            class="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg cursor-pointer hover:bg-gray-100 hover:text-gray-700"
            @click="
              pagination.page > 1
                ? pagination.page-- && getOpportunities()
                : null
            "
          >
            &larr;
          </li>
          <span v-for="index in paginationCount" :key="index">
            <li
              v-if="
                index + pagination.page - 3 >= 1 &&
                index + pagination.page - 3 <= pagination.pageCount
              "
              class="px-3 py-2 leading-tight border border-gray-300 cursor-pointer"
              :style="
                pagination.page === index + pagination.page - 3
                  ? 'background:#000;color:#FFF'
                  : 'background:#FFF;color:#000'
              "
              @click="
                (pagination.page = index + pagination.page - 3),
                  getOpportunities()
              "
            >
              {{ index + pagination.page - 3 }}
            </li>
          </span>
          <li
            class="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg cursor-pointer hover:bg-gray-100 hover:text-gray-700"
            @click="
              pagination.page < pagination.pageCount
                ? pagination.page++ && getOpportunities()
                : null
            "
          >
            &rarr;
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
// import Card from "@/components/Card";
import axios from "axios";
import qs from "qs";
import useSeo from "../composables/useSeo";

export default {
  setup() {
    useSeo({
      title: "Opportunités de Machines Textiles | Jacquart & Fils",
      meta: {
        description:
          "Découvrez nos offres spéciales et nos bonnes affaires du moment sur des équipements textiles d’occasion. Des machines performantes à des prix compétitifs, disponibles immédiatement.",
        "og:type": "website",
      },
      canonical: "https://jacquart.com/opportunities",
    });
  },
  data() {
    return {
      opportunities: [],
      pagination: {
        page: 0,
        pageCount: 0,
        pageSize: 0,
      },
      paginationCount: 5,
      load: true,
    };
  },
  computed() {},
  mounted() {
    this.getOpportunities();
  },
  methods: {
    async getOpportunities() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.load = true;
      this.opportunities = [];
      let query = qs.stringify({
        populate: "*",
        pagination: { page: this.pagination.page, pageSize: 20 },
      });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/api/opportunities?${query}`
        );
        this.opportunities = response.data.data;
        this.pagination = response.data.meta.pagination;
        this.load = false;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
  },
};
</script>
