<template>
  <main class="px-8 bg-white">
    <div class="">
      <div class="firstBackground">
        <div class="flex items-center justify-center pt-80 pb-80">
          <img
            src="@/assets/logo.png"
            alt=""
            class="w-full h-56 md:max-auto sm:max-auto"
          />
        </div>
      </div>
    </div>
    <div
      class="flex flex-col flex-wrap items-center justify-center p-3 space-y-4"
    >
      <div class="flex justify-center w-5/6 pt-10">
        <h4
          class="flex justify-center p-3 text-4xl font-bold text-black bg-gray-200"
        >
          {{ $t("100 years of experience at your service") }}
        </h4>
      </div>
      <div class="flex items-center justify-center lg:w-1/2">
        <p class="flex justify-center p-5 text-lg text-justify">
          {{
            $t(
              "JACQUART company, already handed down from father to son for four generations, is justly proud of its past. Their experience as mill owners gives them the necessary expertise to act as highly qualified intermediaries between companies wishing to sell some individual machines or even their entire factory, and those who wish to buy second-hand textile equipment. Their competence is well-known and recognised by all professionals in the textile industry (mill owners, weavers, dyers, finishers) and those in sales (trade unions, auctioneers, financial institutions...)."
            )
          }}
        </p>
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-center pb-20 md:flex-row md:items-start md:space-x-8 md:max-auto sm:max-auto"
    >
      <div
        class="w-full bg-no-repeat bg-cover md:w-1/2"
        :style="'background-image: url(/history2.jpeg);height:540px'"
      ></div>
      <div class="w-full space-y-5 md:w-1/2">
        <div class="flex flex-col items-center bg-cardColor">
          <div class="pt-4 pb-4">
            <h4 class="flex justify-center p-2 text-white bg-black w-44">
              {{ $t("TRADE") }}
            </h4>
          </div>

          <p class="items-center p-3">
            {{
              $t(
                "Needless to say, this is buying and selling. In the context of our operations we have to buy entire factories or lots of equipment in advance. This is a risk that we do not hesitate to take, in order to have a permanent stock on hand to offer to possible buyers."
              )
            }}
          </p>
        </div>
        <div class="flex flex-col items-center bg-cardColor">
          <div class="pt-4 pb-4">
            <h4 class="flex justify-center p-2 text-white bg-black w-44">
              {{ $t("BROKERAGE") }}
            </h4>
          </div>

          <p class="items-center p-3">
            {{
              $t(
                "In this case we are concerned with selling in your best interests, the equipment which you no longer need, in return for a commission fixed in advance. For this, we regularly publish (more than 35000 copies) a bulletin listing all the equipment available for sale, and distribute it world-wide, based on a file which is updated daily."
              )
            }}
          </p>
        </div>
        <div class="flex flex-col items-center bg-cardColor">
          <div class="pt-4 pb-4">
            <h4 class="flex justify-center p-2 text-white bg-black w-44">
              {{ $t("APPRAISALS") }}
            </h4>
          </div>

          <p class="items-center p-3">
            {{
              $t(
                "Essential as much for the buyer as the seller. We insist on carrying this out in absolute confidentiality for both parties concerned. Our knowledge of the market and our experience in the textile field allow us to take everything into account when making an appraisal."
              )
            }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col items-center justify-center pb-20 md:flex-row-reverse md:items-start md:max-auto sm:max-auto"
    >
      <div
        class="w-full bg-no-repeat bg-cover md:w-1/2 md:ml-8"
        :style="'background-image: url(/history3.jpeg);height:540px'"
      ></div>
      <div class="w-full space-y-5 md:w-1/2">
        <div class="flex flex-col items-center bg-cardColor">
          <div class="pt-4 pb-4">
            <h4 class="flex justify-center p-2 text-white bg-black w-44">
              {{ $t("Purchases - Sales") }}
            </h4>
          </div>

          <p class="items-center p-3">
            {{
              $t(
                "Our team is always ready to inspect equipment for sale on site, wherever its location. Visitors from all over the world take an interest in our second-hand equipment. Our aims in serving our clients are many : Canvassing, going to see equipment to be sold on site, wherever its location, making appraisals, replying to demands, meeting potential buyers…then supervising dimdantling and reassemble of equipment, transport, customs formalities etc... This we do with great passion and always with the essential philosophy of selling in mind : of serving our customers to the maximum."
              )
            }}
          </p>
        </div>
        <div class="flex flex-col items-center bg-cardColor">
          <div class="pt-4 pb-4">
            <h4 class="flex justify-center p-2 text-white bg-black w-44">
              {{ $t("Implantation") }}
            </h4>
          </div>

          <p class="items-center p-3">
            {{
              $t(
                "Our team is always ready to inspect equipment for sale on site, wherever its location. Visitors from all over the world take an interest in our second-hand equipment. Our aims in serving our clients are many : Canvassing, going to see equipment to be sold on site, wherever its location, making appraisals, replying to demands, meeting potential buyers…then supervising dimdantling and reassemble of equipment, transport, customs formalities etc... This we do with great passion and always with the essential philosophy of selling in mind : of serving our customers to the maximum."
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import useSeo from "../composables/useSeo";

export default {
  setup() {
    useSeo({
      title: "Histoire de JACQUART | Expertise en Machines Textiles",
      meta: {
        description:
          "Plongez dans l’histoire de Jacquart & Fils, une entreprise familiale forte de quatre générations d’expertise dans l’achat, la vente et le courtage de machines textiles d’occasion.",
        "og:type": "website",
      },
      canonical: "https://jacquart.com/company",
    });
  },
};
</script>

<style>
.firstBackground {
  background: url("../assets/history.jpg") center center/ cover no-repeat;
}
</style>
