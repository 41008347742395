<template>
  <main class="container mx-auto" @click="checkParentModal">
    <div class="flex flex-col items-center justify-center py-4 mx-auto my-5">
      <h1
        class="p-4 mb-3 text-3xl font-bold text-center text-white bg-black rounded"
      >
        {{ $t("CONTACT US") }}
      </h1>
    </div>
    <div class="mb-10 bg-gray-100 rounded shadow-lg hover:shadow-2xl">
      <div class="px-6 py-6">
        <div
          class="mx-2 my-2 md:grid md:grid-cols-3 sm:flex sm:flex-wrap sm:justify-center"
        >
          <div class="col-span-1 mx-2 my-5">
            <div class="flex flex-col justify-center mb-6">
              <div class="flex flex-col items-center justify-center mx-auto">
                <h3
                  class="p-4 mb-3 text-xl font-bold text-center text-white bg-black rounded"
                >
                  FRANCE
                </h3>
              </div>
              <ul class="list-none">
                <li class="py-1">
                  <a
                    href="https://goo.gl/maps/ZzfwmwEVEXHrkm1L8"
                    target="_blank"
                    class="flex items-center text-gray-600 hover:text-green-600"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    33 Chemin de Cézanne, 13016 Marseille - France
                  </a>
                </li>
                <li class="py-1">
                  <a
                    href="mailto:contact@jacquart.com?subject=Information"
                    class="flex items-center text-gray-600 hover:text-green-600"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                      />
                    </svg>
                    contact@jacquart.com
                  </a>
                </li>
              </ul>

              <div
                class="flex flex-col items-center justify-center mx-auto mt-3"
              >
                <h3
                  class="p-4 mb-3 text-xl font-bold text-center text-white bg-black rounded"
                >
                  {{ $t("ITALY") }}
                </h3>
              </div>

              <ul class="list-none">
                <li class="py-1">
                  <a
                    href="https://goo.gl/maps/ZMDSa5K5opY2CPQKA"
                    target="_blank"
                    class="flex items-center text-gray-600 hover:text-green-600"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    Via Mazzini 39 13900 Biella - ITALIA
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <FormContact
            ref="childComponent"
            class="col-span-2 mx-2 my-5"
            id="modal"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import FormContact from "@/components/FormContact";
import useSeo from "../composables/useSeo";

export default {
  setup() {
    useSeo({
      title: "Contactez JACQUART | Machines Textiles d'Occasion",
      meta: {
        description:
          "Besoin d’informations ou d’un accompagnement personnalisé ? Contactez notre équipe pour toute demande sur l’achat ou la vente de machines textiles d’occasion. Nous sommes à votre écoute.",
        "og:type": "website",
      },
      canonical: "https://jacquart.com/contact",
    });
  },
  components: {
    FormContact,
  },
  methods: {
    checkParentModal(event) {
      let parent = event.target;
      let found = false;
      while (parent) {
        if (parent.id === "modal") {
          found = true;
          break; // Sortir de la boucle dès qu'on trouve "modal"
        }
        parent = parent.parentElement;
      }
      if (!found && this.$refs.childComponent) {
        this.$refs.childComponent.hideModalFromParent();
      }
    },
  },
};
</script>
