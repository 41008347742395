<template>
  <main v-if="!load">
    <div class="container flex justify-between p-10 mx-auto">
      <div class="grid justify-center w-full justify-items-center md:flex md:flex-row sm:grid">
        <div class="w-full md:w-2/3 md:pr-10">
          <Slider v-if="
            machine.images.data?.filter(
              (img) => img.attributes.caption === 'true'
            ).length > 0
          " :imgs="machine.images.data?.filter(
              (img) => img.attributes.caption === 'true'
            )
              " :alt="`${machine.Brand} ${machine.Type} - ${machine.Code}`" />

          <div v-else>{{ $t("no-pictures") }}</div>
        </div>
        <div class="w-full md:w-1/3">
          <div class="flex flex-wrap items-center justify-center p-4 mx-auto" v-if="machine.Type">
            <h4 class="p-4 overflow-hidden text-3xl text-center text-white bg-black rounded shadow-md text-bold">
              {{ $t("Type : ") }}{{ machine.Type }}
            </h4>
          </div>

          <hr />
          <div class="p-4 space-y-2">
            <div class="flex items-end p-2 space-x-2 bg-gray-200">
              <h4 class="h-full text-2xl text-black text-extrabold">
                {{ $t("Ref :") }}
              </h4>
              <p class="h-full text-1xl">
                {{
                  machine.bible_material.data.attributes.code.trim() +
                  "-" +
                  machine.Code
                }}
              </p>
            </div>
            <div class="flex items-end p-2 space-x-2 bg-gray-200">
              <h4 class="flex-shrink-0 text-2xl text-black text-extrabold">
                {{ $t("Brand :") }}
              </h4>
              <p class="text-1xl">{{ machine.Brand }}</p>
            </div>
            <div class="flex items-end p-2 space-x-2 bg-gray-200">
              <h4 class="text-2xl text-black text-extrabold">
                {{ $t("Year :") }}
              </h4>
              <p class="text-1xl">
                {{ machine.contructionOld }}
              </p>
            </div>
          </div>
          <hr />
          <div class="space-y-2">
            <h4 class="text-3xl text-black text-extrabold">
              {{ $t("Specs :") }}
            </h4>
            <div v-if="
              ($i18n.locale == 'fr' && machine.descriptionFR?.trim()) ||
              ($i18n.locale == 'en' && machine.decriptionEN?.trim())
            " class="p-3 bg-gray-200 rounded text-1xl">
              <p v-html="formattedText(
                $i18n.locale == 'fr'
                  ? machine.descriptionFR
                  : machine.decriptionEN
              )
                "></p>
            </div>
            <p v-else class="h-16 text-base leading-relaxed">
              {{ $t("description_not_available") }}
            </p>
          </div>
          <hr class="my-4" />
          <button class="flex p-2 space-x-2 font-semibold text-white bg-black rounded" @click="showForm = true">
            <p>{{ $t("interested") }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-thumbs-up">
              <path
                d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3">
              </path>
            </svg>
          </button>
        </div>
      </div>
      <!-- interested form -->
      <div v-if="showForm" class="fixed top-0 left-0 z-10 flex w-full h-full p-2 text-center bg-black bg-opacity-50">
        <div class="relative w-full p-12 m-auto bg-white sm:w-2/3 lg:w-1/2 xl:1/3">
          <div class="grid space-x-0 sm:space-x-2 sm:grid-cols-2">
            <div class="flex flex-col mb-6">
              <label for="lastname">{{ $t("Lastname") }}</label>
              <input v-model="lastname" type="text" id="lastname" name="lastname"
                class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                required />
            </div>
            <div class="flex flex-col mb-6">
              <label for="companyName">{{ $t("companyName") + "*" }}</label>
              <input v-model="companyname" type="text" id="companyName" name="companyName"
                class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                required />
            </div>
          </div>
          <div class="grid space-x-0 sm:space-x-2 sm:grid-cols-2">
            <div class="flex flex-col mb-6">
              <label for="email">{{ $t("Email") }}</label>
              <input v-model="email" type="text" id="email" name="email"
                class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                required />
            </div>
            <div class="flex flex-col mb-6">
              <label for="country">{{ $t("country") + "*" }}</label>
              <input v-model="country" type="text" id="country" name="country"
                class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                required />
            </div>
          </div>
          <div class="flex flex-col mb-6">
            <label for="address">{{ $t("address") + "*" }}</label>
            <input v-model="address" type="text" id="address" name="address"
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              required />
          </div>
          <div class="flex flex-col justify-center mb-6">
            <label for="description">{{ $t("Message") }}</label>
            <textarea v-model="description" type="text" id="description" name="description"
              class="resize-none form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              rows="4" required />
          </div>
          <div class="mt-10">
            <button @click="sendInterested()"
              class="px-6 py-2 text-xs font-medium leading-tight text-green-700 uppercase transition duration-150 ease-in-out border-2 border-green-700 rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0">
              {{ $t("Send") }}
            </button>
          </div>
          <button
            class="absolute flex items-center justify-center w-8 h-8 text-white bg-black rounded-full -top-2 -right-2"
            @click="showForm = flase">
            X
          </button>
        </div>
      </div>
      <!-- card goes here -->
    </div>
    <div class="space-y-4">
      <h3 class="text-2xl font-semibold text-center">Related product</h3>
      <div v-if="machines.length > 0" class="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
        <div class="p-4" v-for="machine in machines" :key="'m' + machine.id">
          <a :href="machine.id">
            <div class="h-full p-6 bg-gray-100 rounded-lg">
              <img class="object-cover object-center w-full mb-6 rounded lg:h-60 xl:h-56 md:h-64 sm:h-72 xs:h-72 h-72"
                :src="machine.attributes.images.data
                    ? machine.attributes.images.data.filter((img) => {
                      return img.attributes.caption === 'true';
                    })[0]?.attributes.url || '/default.jpg'
                    : '/default.jpg'
                  " :alt="'Ref: ' +
                  machine.attributes.bible_material.data.attributes.code.trim() +
                  '-' +
                  machine.attributes.Code
                  " />
              <h3 class="text-xs font-medium tracking-widest text-indigo-500 title-font">
                {{
                  machine.attributes.contructionOld > 0
                    ? machine.attributes.contructionOld
                    : " "
                }}
              </h3>
              <h3 class="mb-4 text-xs font-medium tracking-widest title-font">
                Ref:
                {{
                  machine.attributes.bible_material.data.attributes.code.trim() +
                  "-" +
                  machine.attributes.Code
                }}
              </h3>
              <h2 class="text-lg font-medium text-gray-900 title-font">
                {{ machine.attributes.Brand }}
              </h2>
              <p v-if="
                ($i18n.locale == 'fr' &&
                  machine.attributes.descriptionFR?.trim()) ||
                ($i18n.locale == 'en' &&
                  machine.attributes.decriptionEN?.trim())
              " class="h-16 text-base leading-relaxed">
                {{
                  $i18n.locale == "fr"
                    ? machine.attributes.descriptionFR?.slice(0, 80)
                    : machine.attributes.decriptionEN?.slice(0, 80)
                }}
                {{
                  $i18n.locale == "fr" &&
                    machine.attributes.descriptionFR?.length > 80
                    ? "..."
                    : $i18n.locale == "en" &&
                      machine.attributes.decriptionEN?.length > 80
                      ? "..."
                      : ""
                }}
              </p>
              <p v-else class="h-16 text-base leading-relaxed">
                {{ $t("description_not_available") }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import qs from "qs";
import Slider from "@/components/Slider.vue";
import useSeo from "../composables/useSeo";

export default {
  components: {
    Slider,
  },
  data() {
    return {
      machine: "",
      load: true,
      lastname: "",
      companyname: "",
      country: "",
      address: "",
      email: "",
      showForm: false,
      description: "",
      //
      machines: [],
      inputCode: "",
      inputSubCode: "",
    };
  },
  mounted() {
    this.lastname = localStorage.getItem("lastName");
    this.companyname = localStorage.getItem("companyName");
    this.email = localStorage.getItem("email");
    this.country = localStorage.getItem("country");
    this.address = localStorage.getItem("address");
    this.getMachine();
  },
  methods: {
    async getMachine() {
      this.load = true;
      let query = qs.stringify({
        populate: ["images", "bible_material"],
      });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/api/machine-fiches/${this.$route.params.id}?${query}`
        );
        this.load = false;
        this.machine = response.data.data.attributes;
        this.machine.machine_id = response.data.data.id;
        useSeo({
          title: `JACQUART - ${this.machine.Brand} ${this.machine.Type} - Machine Textile d'Occasion`,
          meta: {
            description: `Découvrez la ${this.machine.Type
              }, une machine textile d'occasion en excellent état, idéale pour ${this.machine.descriptionFR || this.machine.decriptionEN
              }. Parfaite pour les entreprises à la recherche de solutions fiables et économiques.`,
            "og:type": "website",
          },
          canonical: `https://jacquart.com/machine/${this.machine.machine_id}`,
        });

        this.description =
          this.$i18n.locale == "fr"
            ? `Bonjour, je suis intéressé(e) par votre machine portant la référence ${this.machine.bible_material.data.attributes.code.trim() +
            "-" +
            this.machine.Code
            }. Est-ce qu'elle est toujours disponible ? Cordialement`
            : `Hi, I'm interested in your machine with the reference ${this.machine.bible_material.data.attributes.code.trim() +
            "-" +
            this.machine.Code
            }. Is it still available? Sincerely`;
        this.getRaltedMachines();
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
    async getRaltedMachines() {
      this.machines = [];

      if (!this.machine.bible_material.data.attributes.code) {
        return;
      }

      let query = qs.stringify({
        sort: "validDate:desc",
        populate: ["images", "bible_material"],
        pagination: { page: 1, pageSize: 3 },
        filters: {
          $and: [
            { Status: { $eq: "SELLING" } },
            { website: { $eq: 1 } },
            {
              bible_material: {
                code: {
                  $startsWith:
                    this.machine.bible_material.data.attributes.code.trim(),
                },
              },
            },
            { id: { $ne: this.machine.machine_id } }, // Exclude product with id 10012609
          ],
        },
      });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/api/machine-fiches?${query}`
        );
        this.machines = response.data.data;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
    async sendInterested() {
      localStorage.setItem("lastName", this.lastname);
      localStorage.setItem("companyName", this.companyname);
      localStorage.setItem("email", this.email);
      localStorage.setItem("country", this.country);
      if (
        this.email &&
        this.lastname &&
        this.companyname &&
        this.description &&
        this.address
      ) {
        try {
          await axios.post(
            `${process.env.VUE_APP_STRAPI_API_URL}/api/interested-machines/`,
            {
              data: {
                email: this.email,
                last_name: this.lastname,
                company_name: this.companyname,
                country: this.country,
                machine_fiche: this.$route.params.id,
                address: this.address,
              },
            }
          );
          this.showForm = false;
          this.sendMail();
        } catch (error) {
          alert(this.$t("send-error"));
          console.log(error);
        }
      } else {
        alert(this.$t("all-fields"));
      }
    },
    async sendMail() {
      const text = `
      <div>
        <p>Nom: ${this.lastname}</p>
        <p>E-mail: ${this.email}</p>
        <p>Nom de l'entreprise: ${this.companyname}</p>
        <p>Adresse: ${this.address}</p>
        <p>Pays: ${this.country}</p>
        <p>Code machine: ${this.machine.bible_material.data.attributes.code.trim() +
        "-" +
        this.machine.Code
        }</p>
        <br>
        <p>${this.description}</p>
      </div>
      `;
      const data = {
        to: "contact@jacquart.com",
        subject: "Demande d'intérêt",
        text: text,
        from: "development@atelier.ovh",
      };
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_STRAPI_API_URL}/api/email`,
          data
        );
        if (response) {
          alert(this.$t("send-success"));
        }
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
    formattedText(description) {
      return description.replace(/\r\n/g, "<br>");
    },
  },
};
</script>
