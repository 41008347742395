<template>
  <main class="bg-white" v-if="ready">
    <iframe
      v-if="opportunities.pdf_fr.data"
      :src="
        $i18n.locale == 'fr'
          ? opportunities.pdf_fr.data.attributes.url +
            '#toolbar=0&zoom=' +
            windowWidth
          : opportunities.pdf_en.data.attributes.url +
            '#toolbar=0&zoom=' +
            windowWidth
      "
      width="100%"
      height="1000"
      type="application/pdf"
    />
    <div class="flex flex-wrap justify-between m-auto md:w-3/5">
      <img
        v-for="(opportunity, index) in opportunities.images.data"
        :key="index"
        class="h-auto p-2 w-60"
        :src="opportunity.attributes.url"
        alt=""
      />
    </div>
  </main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      opportunities: "",
      windowWidth: 0,
      ready: false,
      baseUrl: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
  mounted() {
    this.getMachine();
    this.windowWidth = window.innerWidth * (8 / 100);
    // window.addEventListener("resize", this.onResize);
  },
  methods: {
    async getMachine() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/api/opportunities/${this.$route.params.id}?populate=*`
        );
        this.opportunities = response.data.data.attributes;
        this.ready = true;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth * (10 / 100);
      console.log(this.windowWidth);
    },
  },
};
</script>
